<div class="modal" [ngClass]="{'is-active': this.login}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Login!</p>
        <button class="delete" aria-label="close" (click)="showLogin()"></button>
      </header>
      <section class="modal-card-body">
        <form action="" [formGroup]="loginForm" (ngSubmit)="Login()">
          <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" formControlName="username" type="email" placeholder="Email" value="" [ngClass]="{'is-danger': loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched && loginForm.controls.username.errors.required)}">
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right" *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched && loginForm.controls.username.errors.required)">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
            <p class="help is-danger" *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched && loginForm.controls.username.errors.required)">Please submit your email</p>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" type="password" placeholder="Password" value="" formControlName="password" [ngClass]="{'is-danger': loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched && loginForm.controls.password.errors.required)}">
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
              <span class="icon is-small is-right" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched && loginForm.controls.password.errors.required)">
                <i class="fas fa-exclamation-triangle "></i>
              </span> 
            </div>
            <p class="help is-danger" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched && loginForm.controls.password.errors.required)">Please submit your password</p>
          </div>
          <input type="submit" value="" [disabled]="this.loginForm.invalid" style="display:none;">
        </form>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="Login()" [disabled]="this.loginForm.invalid">Log in!</button>
      <button class="button" (click)="showLogin()">Cancel</button>
      <br>
    <div class="buttons">
      <a class="button is-facebook" (click)="LoginViaFacebook()">
        <span class="icon">
          <i class="fab fa-facebook"></i>
        </span>
        <span>Sign in with Facebook</span>
      </a><br>
      <a class="button is-google" (click)="LoginViaGoogle()">
        <span class="icon">
          <i class="fab fa-google"></i>
        </span>
        <span>Sign in with Google</span>
      </a>
    </div>
    </footer>
    </div>
  </div>
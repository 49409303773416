import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Comment, Project, ProjectsService } from '../projectsservice.service';
import { delay, map } from 'rxjs/operators';
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Swiper,
} from 'swiper/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService, User } from 'src/app/auth/auth.service';
import { getLocaleDateTimeFormat } from '@angular/common';
import { timestamp, Timestamp } from 'rxjs/internal/operators/timestamp';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-projectinfo',
  templateUrl: './projectinfo.component.html',
  styleUrls: ['./projectinfo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectinfoComponent implements OnInit {

  public busy:boolean;

  public id:string;

  public project: Project;

  public comments: Comment[] = [];

  public users: any[] = []

  public commentError = false;

  constructor(private route:ActivatedRoute,
              private projectService: ProjectsService,
              public authService: AuthService) { }

   ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.busy = true
    this.projectService.getProjectById(this.id).then(data => {
      this.project = {
        id: data.pid,
        title: data.title,
        undertitle: data.undertitle,
        URL: data.URL,
        fotoURL: data.fotoUrl,
        text: data.text
      };


      this.authService.getAllUsers().then(data => {
        this.users = this.authService.allUsers
        this.projectService.getCommentByProjectId(this.project.id).then(data =>{
          data.forEach(doc => {
            const docdata:any = doc.data();
            if (this.project.id == docdata.pid){
              this.comments.push({
                uid: docdata.uid,
                pid: docdata.pid,
                comment: docdata.comment,
                time: docdata.time,
                user: this.getSpecificUser(docdata.uid)
              })
            }
          });
        this.busy = false;
      });
      })
    });
  }

  addComment(comment){
    if (comment.value.trim() == ""){
      this.commentError = true;
      return
    }
    let newComment:Comment ={
      comment: comment.value,
      pid: this.project.id,
      uid: this.authService.userState.uid,
      time: Date.now()
    }
    this.projectService.addComment(newComment).then(res => {
      newComment.user = this.authService.userState
      this.comments.push(newComment);
      this.commentError = false;
      comment.value = ""
    }), error =>{
      console.error(error)
    }
  }

  getSpecificUser(uid):any{
    let chosenUser:any = undefined;
    this.users.forEach(user => {
      if (user.uid == uid){
        chosenUser = user
      }
    });
    return chosenUser
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { UserInfoComponent } from './auth/user-info/user-info.component';
import { ProjectinfoComponent } from './projects/projectinfo/projectinfo.component';
import { ProjectsComponent } from './projects/projects.component';
import { HomeComponent } from './router_files/home/home.component';


const routes: Routes = [
  {path:'user', component: UserInfoComponent},
  {path:'home', component: HomeComponent},
  {path:'projects', component: ProjectsComponent},
  {path:'projects/:id', component: ProjectinfoComponent},
  {path:'contact', component: ContactComponent},
  {path:'**', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

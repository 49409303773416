<div class="container mt-6 is-fullhd" *ngIf="busy">
    <section class="section">
      <div class="container">
        <div class="columns">
          <div style="text-align: center;" class="column">
            <img src="../../../assets/loading-buffering.gif" alt="">
          </div>
        </div>
      </div>
    </section>
  </div>
<div class="tile is-ancestor mt-6 ml-2 mr-2 is-flex-wrap-wrap" *ngIf="!busy">
    <div class="tile is-parent is-4" *ngFor="let project of Projects">
        <div class="tile is-child box notification is-link is-clickable" [routerLink]="['/projects', project.id]" routerLinkActive="router-link-active">
            <p class="title">{{ project.title }}</p>
            <p class="subtitle"> {{ project.undertitle}} </p>
            <figure class="image is-4by3">
                <img [src]="project.fotoURL[0]">
            </figure>
              <small>Click for more info >></small>
        </div>    
    </div>
  </div>
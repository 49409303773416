<div class="column mt-6 placeholder"></div>
<form action="" [formGroup]="contactForm">
    <div class="columns m-6 is-multiline is-centered is-6">
            <div class="column is-half">
            <label for="firstname">First name:</label>
            <div class="control">
                <input type="text" class="input" id="lastname" [ngClass]="{'is-danger': firstName.invalid && (firstName.dirty || firstName.touched)}" formControlName="firstName" placeholder="e.g. first name" required>
                <div class="is-text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <small *ngIf="firstName.errors?.required" class="has-text-danger">
                        Firstname needs to be filled in.
                    </small>
                </div>
            </div>
        </div>
        <div class="column is-half">
            <label for="lastname">Last name:</label>
            <div class="control">
                <input type="text" class="input" id="lastname" [ngClass]="{'is-danger': lastName.invalid && (lastName.dirty || lastName.touched)}" formControlName="lastName" placeholder="e.g. last name" required>
                <div class="is-text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <small *ngIf="lastName.errors?.required" class="has-text-danger">
                        Lastname needs to be filled in.
                    </small>
                </div>
            </div>
        </div>
        <div class="column is-full">
            <label for="email">Email:</label>
            <div class="control">
                <input type="email" id="email" [ngClass]="{'is-danger': email.invalid && (email.dirty || email.touched)}" class="input" formControlName="email" placeholder="e.g. email@email.com" required>
                <div class="is-text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <small *ngIf="email.errors?.required" class="has-text-danger">
                        Email needs to be filled in.
                    </small>
                </div>
            </div>
        </div>
        <div class="column is-full">
            <label for="message">Message:</label>
            <div class="control">
                <textarea class="textarea" [ngClass]="{'is-danger': message.invalid && (message.dirty || message.touched)}" placeholder="e.g. Hello!" formControlName="message" required></textarea>
                <div class="is-text-danger" *ngIf="message.invalid && (message.dirty || message.touched)">
                    <small *ngIf="message.errors?.required" class="has-text-danger">
                        Message needs to be filled in.
                    </small>
                </div>
            </div>
        </div>
        <div class="column is-full">
            <button class="button" [ngClass]="{'is-loading': this.busy}" (click)="submitForm(contactForm.value)" [disabled]="!contactForm.valid">Send</button> 
        </div>
        <div class="column">
            <p class="has-text-success">{{returnMessage}}</p>
        </div>
    </div>
</form>
<section class="hero is-link is-fullheight" src="">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <p class="title">
              Welcome to my portfolio website!
            </p>
            <br>
            <p class="subtitle text">
              My name is John Klees. I got my diploma for Mediatech developer and I'm currently studying to become a software engineer. I hope to become a successfull full stack developer.
            </p>
            <p class="subtitle text">
              My interest are: Soccer, playing video games, tennis, basketball, developing, swimming and riding my motorcycle.
            </p>
          <p class="subtitle">
              On the tab "Projects" you can look at all the projects that I've made!
            </p>
            <br>
            <p class="subtitle">
              <a class="button is-black" href="https://www.linkedin.com/in/john-klees-3a105a14a" target="_blank">
                <span class="icon">
                  <i class="fab fa-linkedin"></i>
                </span>
                <span>Check my LinkedIn</span>
              </a>
              <a class="button " [routerLink]="['/projects']" routerLinkActive="router-link-active">
                <span class="icon">
                  <img src="../../assets/rocket.png">
                </span>
                <span>Check my Projects</span>
              </a>
            </p>

          <p class="subtitle">
            Hopefully we can start an opportunity together!
          </p>

          </div>
          <div class="column">
            <img src="../../assets/ProfilePic.jpg" style="height: 10%; width: 65%;">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- [style.background-image]="'url(../../assets/ProfilePic.jpg)'" -->

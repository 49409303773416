<app-header></app-header>
<router-outlet></router-outlet>
<div class="notification is-success is-light animation" (click)="closeNotification()" *ngIf="notification">
  <button class="delete" (click)="closeNotification()"></button>
  You've logged in successfully!
</div>
<!-- 
<script>
    bulmaCarousel.attach('#carousel-demo', {
      slidesToScroll: 1,
      slidesToShow: 4
    });
    </script> -->
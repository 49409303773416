import { Component, OnInit } from '@angular/core';
import { Project, ProjectsService } from './projectsservice.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  public Projects: Project[] = [];

  busy = true
  constructor(private ProjectService: ProjectsService) { 

  }

  ngOnInit(): void {
    this.ProjectService.getProjectsFromFirebase().then(data =>{
      data.docs.forEach(doc => {
        let docdata:any = doc.data()
        let document = doc
        this.Projects.push({
            id: document.id,
            title: docdata.title,
            undertitle: docdata.undertitle,
            fotoURL: docdata.fotoUrl,
            URL: docdata.URL
        })
      });
      this.busy = false
    })
  }

}

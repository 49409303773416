import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import firebase from 'firebase/app';
import 'firebase/app';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { User } from '../auth/auth.service';

export interface Project {
    id:string,
    title: string,
    undertitle: string,
    fotoURL: string[],
    URL?:string,
    text?: string,

}
export interface Comment {
    pid:string,
    uid: string,
    comment: string,
    time: number,
    user?: User
}

@Injectable({
  providedIn: 'root'
})

export class ProjectsService {
  public projects: Project[] = []

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    ){
      
    }

    getProjectById(id:string):any{
        return this.afs.collection('projects').doc(id).get().toPromise().then(data => {
            return data.data()
        })

    }

    getCommentByProjectId(id:string):any{
        return this.afs.collection('comments').get().toPromise().then(data => {
            return data
        })

    }

    addComment(comment:any):any{
        return this.afs.collection('comments').add(comment)
    }

    getProjectsFromFirebase():any{
        return this.afs.collection('projects').get().toPromise().then(data =>{
            return data
        }, error => {
            console.error(error)
        })
    }
  
    // SignIn(email, password) {
    //   return this.afAuth.signInWithEmailAndPassword(email, password)
    //     .then((result) => {
    //       this.ngZone.run(() => {
    //         this.router.navigate(['Home']);
    //       });
    //       if(!result.user.emailVerified){
    //         alert("You need to verify your email first.")
    //       }
    //       this.SetUserData(result.user);
    //     }).catch((error) => {
    //       window.alert(error.message)
    //     })
    // }
  
    // SignUp(email, password) {
    //   return this.afAuth.createUserWithEmailAndPassword(email, password)
    //     .then((result) => {
    //       this.SendVerificationMail();
    //       this.SetUserData(result.user);
    //     }).catch((error) => {
    //       window.alert(error.message)
    //     })
    // }

    // SendVerificationMail() {
    //     return this.afAuth.currentUser.then(u => u.sendEmailVerification())
    //     .then(() => {
    //       this.afAuth.currentUser.then((u) => alert(u.email + " has been added!"))
    //     })
    // }    
  
    // ForgotPassword(passwordResetEmail) {
    //   return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    //   .then(() => {
    //     window.alert('Password reset email sent, check your inbox.');
    //   }).catch((error) => {
    //     window.alert(error)
    //   })
    // }
  
    // isLoggedInWithoutVerified(): boolean {
    //   const user = JSON.parse(localStorage.getItem('user'));
    //   return (user !== null) ? true : false;
    // }

    // isLoggedInWithverified(): boolean {
    //   const user = JSON.parse(localStorage.getItem('user'));
    //   return (user !== null && user.emailVerified !== false) ? true : false;
    // }

    // GoogleAuth() {
    //   return this.AuthLogin(new firebase.auth.GoogleAuthProvider);
    // }
    // FacebookAuth() {
    //   return this.AuthLogin(new firebase.auth.FacebookAuthProvider);
    // }
  
    // AuthLogin(provider) {
    //   return this.afAuth.signInWithPopup(provider)
    //   .then((result) => {
    //      this.ngZone.run((res) => {
    //         console.log(res)
    //       })
    //     this.SetUserData(result.user);
    //   }).catch((error) => {
    //     window.alert(error)
    //   })
    // }
  
    // SetUserData(user) {
    //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    //   const userState: User = {
    //     uid: user.uid,
    //     email: user.email,
    //     displayName: user.displayName,
    //     photoURL: user.photoURL,
    //     emailVerified: user.emailVerified
    //   }
    //   return userRef.set(userState, {
    //     merge: true
    //   })
    // }
}

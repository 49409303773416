import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeaderComponent } from 'src/app/header/header.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  register:boolean;
  registerForm: FormGroup;

  constructor(private HeaderComponent:HeaderComponent,
              private formBuilder: FormBuilder,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.register = this.HeaderComponent.register;
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', Validators.required],
      password2: ['', Validators.required],
    })
  }

  showRegister(){
    this.HeaderComponent.register = !this.HeaderComponent.register
  }

  Register(){
    this.authService.SignUp(this.registerForm.value.email, this.registerForm.value.password)
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, User } from '../auth.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  user: User;
  UserInfoForm: FormGroup;

  returnMessage = ""

  constructor(private authService: AuthService,
              private FormBuilder: FormBuilder) { }

  async ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.UserInfoForm = this.FormBuilder.group({
      displayName: [this.user.displayName, Validators.required],
      email: [this.user.email, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    })
  }
  updateUser(){
    const olduser = this.authService.userState
    let newUser = {
      uid: olduser.uid,
      displayName: this.UserInfoForm.value.displayName,
      email: olduser.email,
      emailVerified: olduser.emailVerified,
      photoURL: olduser.photoURL
    } 
    this.authService.updateUser(newUser).then(data => {
      this.returnMessage = "User updated successfully!"
    }), error =>{
      alert("Something went wrong")
    }
  }
}

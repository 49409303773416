<section class="section is-medium">
    <form [formGroup]="UserInfoForm">
        <div class="field">
            <label class="label">Display Name</label>
            <div class="control has-icons-left has-icons-right">
                <input class="input" type="text" placeholder="Display Name" formControlName="displayName" [ngClass]="{'is-danger': UserInfoForm.controls.displayName.invalid && (UserInfoForm.controls.displayName.dirty || UserInfoForm.controls.displayName.touched && UserInfoForm.controls.displayName.errors.required)}">
                <span class="icon is-small is-left">
                <i class="fas fa-user"></i>
                </span>
                <span class="icon is-small is-right" *ngIf="UserInfoForm.controls.displayName.invalid && (UserInfoForm.controls.displayName.dirty || UserInfoForm.controls.displayName.touched && UserInfoForm.controls.displayName.errors.required)">
                <i class="fas fa-exclamation-triangle"></i>
                </span>
            </div>
            <p class="help is-danger" *ngIf="UserInfoForm.controls.displayName.invalid && (UserInfoForm.controls.displayName.dirty || UserInfoForm.controls.displayName.touched && UserInfoForm.controls.displayName.errors.required)">Please fill in your display name</p>
            </div>          
        <div class="field">
        <label class="label">Email</label>
        <div class="control has-icons-left has-icons-right">
            <input disabled class="input" type="email" placeholder="Email" formControlName="email" [ngClass]="{'is-danger': UserInfoForm.controls.email.invalid && (UserInfoForm.controls.email.dirty || UserInfoForm.controls.email.touched && UserInfoForm.controls.email.errors.required)}">
            <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
            </span>
            <span class="icon is-small is-right" *ngIf="UserInfoForm.controls.email.invalid && (UserInfoForm.controls.email.dirty || UserInfoForm.controls.email.touched && UserInfoForm.controls.email.errors.required)">
            <i class="fas fa-exclamation-triangle"></i>
            </span>
        </div>
        <p class="help is-danger" *ngIf="UserInfoForm.controls.email.invalid && (UserInfoForm.controls.email.dirty || UserInfoForm.controls.email.touched && UserInfoForm.controls.email.errors.required)">Please submit your email</p>
        </div>

        <!-- <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left has-icons-right">
            <input class="input" type="password" placeholder="Password" value="" formControlName="password" [ngClass]="{'is-danger': UserInfoForm.controls.password.invalid && (UserInfoForm.controls.password.dirty || UserInfoForm.controls.password.touched && UserInfoForm.controls.password.errors.required)}">
            <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
            </span>
            <span class="icon is-small is-right" *ngIf="UserInfoForm.controls.password.invalid && (UserInfoForm.controls.password.dirty || UserInfoForm.controls.password.touched && UserInfoForm.controls.password.errors.required)">
            <i class="fas fa-exclamation-triangle "></i>
            </span>
        <p class="help is-danger" *ngIf="UserInfoForm.controls.password.invalid && (UserInfoForm.controls.password.dirty || UserInfoForm.controls.password.touched && UserInfoForm.controls.password.errors.required)">Please submit your password</p>
        </div>
        </div> -->
        <button type="submit" [disabled]="!UserInfoForm.valid" class="button" (click)="updateUser()">Update</button>
        <p class="has-text-success">{{returnMessage}}</p>

    </form>
</section>
<nav class="navbar is-dark is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item">
        <h1 class="title has-text-white">John Klees</h1>
    </a>
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" [ngClass]="{'is-active': this.expanded}" (click)="expand()">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div id="navbarBasicExample" class="navbar-menu " [ngClass]="{'is-active': this.expanded}">
      <div class="navbar-start">
        <a class="navbar-item" [routerLink]="['/home']" routerLinkActive="router-link-active" >
          Home
        </a>

        <a class="navbar-item" [routerLink]="['/projects']" routerLinkActive="router-link-active">
          Projects
        </a>
  
        <a class="navbar-item" [routerLink]="['/contact']" routerLinkActive="router-link-active" >
          Contact
        </a>
  
        <!-- <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            More
          </a>
  
          <div class="navbar-dropdown">
            <a class="navbar-item">
              About
            </a>
            <a class="navbar-item">
              Jobs
            </a>
            <a class="navbar-item">
              Contact
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item">
              Report an issue
            </a>
          </div>
        </div> -->
      </div>
  
      <div class="navbar-end" *ngIf="!this.isLoggedIn()">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-primary" (click)="showRegister()">
              <strong>Sign up</strong>
            </a>
            <a class="button is-light" (click)="showLogin()">
              Log in
            </a>
          </div>
        </div>
      </div>
      <div class="navbar-end" *ngIf="this.isLoggedIn()">
        <div class="navbar-item">
          <div class="buttons">
            <a *ngIf="this.user.photoURL == null" class="button is-light is-rounded is-32x32" [routerLink]="['/user']" routerLinkActive="router-link-active">
              <p >{{this.returnUserEmail().charAt(0) | titlecase }}</p>
            </a>
            <figure class="image is-32x32 m-2" *ngIf="this.user.photoURL != null" [routerLink]="['/user']" routerLinkActive="router-link-active" >
              <img class="is-rounded" [src]="this.user.photoURL">
            </figure>
            <a class="button is-danger" (click)="Logout()">
              Log out
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <app-register *ngIf="this.register"></app-register>
  <app-login *ngIf="this.login"></app-login>

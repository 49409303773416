<div class="placeholder columns mt-3"></div>
<div class="container mt-6 is-fullhd" *ngIf="busy">
  <section class="section">
    <div class="container">
      <div class="columns">
        <div style="text-align: center;" class="column">
          <img src="../../../assets/loading-buffering.gif" alt="">
        </div>
      </div>
    </div>
  </section>
</div>
<div class="columns m-6" *ngIf="!busy">
  <div class="column">
      <div class="title">{{this.project.title}}</div>
      <p>{{this.project.text}}</p>
  <br/>
      <p *ngIf="this.project.URL != null">Link to this webpage: <a target="_blank" href="{{this.project.URL}}">{{this.project.URL}}</a></p>
  </div>
  <div class="column">
    <div class="column is-scrollable" *ngIf="this.comments.length > 0">
      <article class="media" *ngFor="let comment of this.comments">
        <figure class="media-left">
          <p class="button is-danger is-rounded is-32x32" *ngIf="comment.user != null && !this.busy">
            {{comment.user.email.charAt(0) | titlecase }}
          </p>
          <p class="image is-64x64" *ngIf="comment.user == null">
            User not found
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <p>
              <strong *ngIf="this.getSpecificUser(comment.uid) != null && !this.busy">{{comment.user.email.charAt(0).displayname}} </strong> <small *ngIf="this.getSpecificUser(comment.uid) != null && !this.busy">{{ comment.user.email }}</small>
              <strong *ngIf="this.getSpecificUser(comment.uid) == null && !this.busy">Unknown </strong> <small> Posted on: {{ comment.time | date:'medium'}}</small>
              <br>
              {{comment.comment}}
            </p>
          </div>
        </div>
      </article>
    </div>
    <div class="column">Do you have any tips or tops for this project, feel free to let me know!</div>
    <article class="media" *ngIf="!this.authService.isLoggedInWithverified()">
      <div class="media-content">
        <div class="field">
          <p class="control">
            <textarea class="textarea" disabled #text placeholder="You need to be logged in and verified to place a comment"></textarea>
            <button *ngIf="this.authService.isLoggedInWithverified" class="button">Verify</button>
          </p>
        </div>
        <div class="field">
          <small class="has-text-danger" *ngIf="this.commentError">Comment needs to be filled in!</small>
          <p class="control">
            <button class="button" (click)="addComment(text)" [disabled]="!this.authService.isLoggedInWithverified()">Post comment</button>
          </p>
        </div>
      </div>
    </article>
    <article class="media" *ngIf="(this.authService.isLoggedInWithoutVerified())">
      <figure class="media-left">
        <p class="button is-danger is-rounded is-64x64">
          {{this.authService.userState.email.charAt(0) | titlecase }}
        </p>
      </figure>
      <div class="media-content">
        <div class="field">
          <small>{{this.authService.userState.email}}</small>
          <p class="control">
            <textarea class="textarea" #text placeholder="Add a comment..."></textarea>
          </p>
        </div>
        <div class="field">
          <small class="has-text-danger" *ngIf="this.commentError">Comment needs to be filled in!</small>
          <p class="control">
            <button class="button" (click)="addComment(text)">Post comment</button>
          </p>
        </div>
      </div>
    </article>
  </div>
</div>

<swiper
[slidesPerView]="1"
[spaceBetween]="30"
[loop]="true"
[speed]=500
[navigation]="true"
[pagination]="{ clickable: true }"
class="carousel"
*ngIf="!busy"
>
  <ng-container *ngFor="let image of this.project.fotoURL">
    <ng-template swiperSlide><img style="margin-left:25%; width: 50%; height: 50%; text-align: center;" src="{{image}}" alt=""></ng-template>
  </ng-container>
</swiper>

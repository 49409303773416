import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  expanded = false;
  register = false;
  login = false;
  user: User;
  constructor(private authService:AuthService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  expand(){
    this.expanded = !this.expanded
  }

  returnUserEmail(): string{
    this.user = JSON.parse(localStorage.getItem('user'));
    return this.user.email;
  }

  isLoggedIn(){
    this.user = JSON.parse(localStorage.getItem('user'));
    return this.authService.isLoggedInWithoutVerified()
  }

  Logout(){
    this.authService.SignOut()
  }

  showLogin(){
    this.login = !this.login
  }

  showRegister(){
    this.register = !this.register
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from './message.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup

  constructor(private fb : FormBuilder,
              private messageService: MessageService) {}

  busy = false

  returnMessage = ""

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName:  new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
    })
  }

  submitForm(contactForm){
    this.busy = true;
    this.messageService.addMessage(contactForm).then(data =>{
      this.returnMessage = "Message is received!"
      this.busy = false;
    }), error =>{
      this.busy = false
      console.error(error)
      this.returnMessage = "Something went wrong."      
    }

}

  get firstName() { return this.contactForm.controls.firstName; }
  get lastName() { return this.contactForm.controls.lastName; }
  get email() { return this.contactForm.controls.email; }
  get message() { return this.contactForm.controls.message; }
}

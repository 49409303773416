import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import firebase from 'firebase/app';
import 'firebase/app';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { User } from '../auth/auth.service';

interface Message{
    firstName: string,
    lastName: string,
    email: string,
    message: string,

}

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  public messages: Message[] = []

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    ){
      
    }

    getmessageById(id:string):any{
        return this.afs.collection('messages').doc(id).get().toPromise().then(data => {
            return data.data()
        })

    }

    addMessage(message:any):any{
        return this.afs.collection('messages').add(message)
    }

    getMessagesFromFirebase():any{
        return this.afs.collection('messages').get().toPromise().then(data =>{
            return data
        }, error => {
            console.error(error)
        })
    }
}

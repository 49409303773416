<div class="modal" [ngClass]="{'is-active': this.register}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Register!</p>
        <button class="delete" aria-label="close" (click)="showRegister()"></button>
      </header>
      <section class="modal-card-body">
        <form action="" [formGroup]="registerForm" (ngSubmit)="Register()">          
          <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" type="email" placeholder="Email" value="" formControlName="email" [ngClass]="{'is-danger': registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched && registerForm.controls.email.errors.required)}">
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right" *ngIf="registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched && registerForm.controls.email.errors.required)">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
            <p class="help is-danger" *ngIf="registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched && registerForm.controls.email.errors.required)">Please submit your email</p>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" type="password" placeholder="Password" value="" formControlName="password" [ngClass]="{'is-danger': registerForm.controls.password.invalid && (registerForm.controls.password.dirty || registerForm.controls.password.touched && registerForm.controls.password.errors.required)}">
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
              <span class="icon is-small is-right" *ngIf="registerForm.controls.password.invalid && (registerForm.controls.password.dirty || registerForm.controls.password.touched && registerForm.controls.password.errors.required)">
                <i class="fas fa-exclamation-triangle "></i>
              </span>
            <p class="help is-danger" *ngIf="registerForm.controls.password.invalid && (registerForm.controls.password.dirty || registerForm.controls.password.touched && registerForm.controls.password.errors.required)">Please submit your password</p>
            </div>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" type="password" placeholder="Password check" value="" formControlName="password2" [ngClass]="{'is-danger': registerForm.controls.password2.invalid && (registerForm.controls.password2.dirty || registerForm.controls.password2.touched && registerForm.controls.password2.errors.required)}">
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
              <span class="icon is-small is-right" *ngIf="registerForm.controls.password2.value == registerForm.controls.password.value && (registerForm.controls.password2.dirty || registerForm.controls.password2.touched && registerForm.controls.password2.errors.required)">
                <i class="fas fa-check"></i>
              </span>
              <span class="icon is-small is-right" *ngIf="registerForm.controls.password2.value != registerForm.controls.password.value && registerForm.controls.password2.invalid && (registerForm.controls.password2.dirty || registerForm.controls.password2.touched && registerForm.controls.password2.errors.required)">
                <i class="fas fa-exclamation-triangle "></i>
              </span>
            </div>
            <p class="help is-danger" *ngIf="registerForm.controls.password2.value != registerForm.controls.password.value && registerForm.controls.password2.invalid && (registerForm.controls.password2.dirty || registerForm.controls.password2.touched && registerForm.controls.password2.errors.required)">This password doesn't match</p>
            <p class="help is-danger" *ngIf="registerForm.controls.password2.invalid && (registerForm.controls.password2.dirty || registerForm.controls.password2.touched && registerForm.controls.password2.errors.required)">Please submit your password</p>
            <p class="help is-success" *ngIf="registerForm.controls.password2.value == registerForm.controls.password.value && (registerForm.controls.password2.dirty || registerForm.controls.password2.touched && registerForm.controls.password2.errors.required)">The password are matching!</p>
            </div>
          <input type="submit" style="display:none" [disabled]="this.registerForm.invalid">
        </form>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="Register()" [disabled]="this.registerForm.invalid">Register!</button>
      <button class="button" (click)="showRegister()">Cancel</button>
    </footer>
    </div>
  </div>
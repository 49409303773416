import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { HeaderComponent } from 'src/app/header/header.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login:boolean;

  loginForm:FormGroup;

  constructor(private HeaderComponent:HeaderComponent,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private AppComponent: AppComponent) { }

  ngOnInit(): void {
    this.login = this.HeaderComponent.login;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  showLogin(){
    this.HeaderComponent.login = !this.HeaderComponent.login
  }

  LoginViaFacebook(){
    this.authService.FacebookAuth().then(data=>{
    }), error => {
      alert("Something went wrong")
    };
    this.showLogin()
  }

  LoginViaGoogle(){
    this.authService.GoogleAuth().then(data=>{
    }), error => {
      alert("Something went wrong")
    };
    this.showLogin()
  }
  async Login(){
    this.authService.SignIn(this.loginForm.value.username,this.loginForm.value.password).then(data=>{
      this.AppComponent.notification = true
      }), error => {
        alert("Something went wrong")
      }
      this.showLogin()

    // this.authService.afAuth.authState.subscribe(user =>{
    //     if(user.emailVerified ){
    //       this.HeaderComponent.login = false;
    //     }else{
    //       alert("You need to verify your account first")
    //     }
    // })
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './router_files/home/home.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './auth/auth.service';
import { UserInfoComponent } from './auth/user-info/user-info.component';
import { ContactComponent } from './contact/contact.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectinfoComponent } from './projects/projectinfo/projectinfo.component';
import { SwiperModule } from 'swiper/angular';
import { ProjectsService } from './projects/projectsservice.service';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from './contact/message.service';

const firebaseConfig = {
  apiKey: "AIzaSyC1JZHuuFNkKg-OMRUgmDtpDSE97kBUXRA",
  authDomain: "portfolio-website-9fb4c.firebaseapp.com",
  databaseURL: "https://portfolio-website-9fb4c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-website-9fb4c",
  storageBucket: "portfolio-website-9fb4c.appspot.com",
  messagingSenderId: "655726928802",
  appId: "1:655726928802:web:6be6e41f146efaa75c08fb",
  measurementId: "G-3L4DLDCWC0"
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    UserInfoComponent,
    ContactComponent,
    ProjectsComponent,
    ProjectinfoComponent,
  ],
  imports: [
    SwiperModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,

  ],
  providers: [AuthService,
              ProjectsService,
              MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }
